* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

input {
  height: 27px;
}

label {
  margin-bottom: 5px;
  margin-top: 10px;
}