.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    gap: 40px;
    font-weight: 600;
}

.notFoundBtn {
    color: white;
    background-color: #ED7F61;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
}

.notFoundBtn:hover {
    cursor: pointer;
}