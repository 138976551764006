.fieldDataForm {
    background-color: #EE7D5F;
    width: 220px;
    margin: 110px 0 0 80px;
    height: 150px;
    box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 5px;
}

.addFieldBtn {
    background-color: white;
    color: #44626a;
    border: none;
    padding: 9px;
    border-radius: 5px;
    margin: 10px;
}

.addFieldBtn:hover {
  color: black;
  cursor: pointer;
}

.addFieldForm {
  position: relative;
  background-color: #44626a;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border-radius: 5px;
  color: white;
  box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  margin-top: 10px;
}

.pairingFlexBox {
  display: flex;
  flex-direction: column;
}

.closeAddFieldForm {
  position: absolute;
  top: 0;
  right: 10px;
  color: rgba(255, 255, 255, 0.657);
  font-size: 20px;
}

.closeAddFieldForm:hover {
  cursor: pointer;
  color: white;
}

.loginSubmit {
  color: white;
  background-color: #ED7F61;
  padding: 10px;
  font-size: 16px;
  border: none;
  box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 25px;
}