.advicesDiv {
  margin: 100px 0 25px 100px;
  display: flex;
  justify-content: flex-start;
}

.addAdviceForm {
  position: relative;
  margin-left: 250px;
  background-color: #44626a;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;
  border-radius: 5px;
  color: white;
  box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
  align-self: center;
  margin-top: 200px;
  align-self: flex-start;
}

.addAdviceBtn {
  background-color: white;
  color: #44626a;
  border: none;
  padding: 9px;
  border-radius: 5px;
  margin: 10px;
}

.addAdviceBtn:hover {
  color: black;
  cursor: pointer;
}

.closeAddadviceForm {
  position: absolute;
  top: 0;
  right: 10px;
  color: rgba(255, 255, 255, 0.657);
  font-size: 20px;
}

.closeAddadviceForm:hover {
  cursor: pointer;
  color: white;
}

.flexAdviceItems {
  width: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}


@media (max-width: 540px) {
  .advicesDiv {
    margin: 0;
  }
 }