.firstHeader {
  color: #44626a;
  margin: 60px 0;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.secondHeader {
  display: flex;
  font-size: 18px;
  background-color: #ee7d5f;
  color: white;
  flex-wrap: wrap;
  width: 100%;
}

.secondHeaderItems {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.788);
  margin: 0 65px;
  padding: 25px;
}

.secondHeaderItems:hover {
  color: white;
}

.icon {
  color: #44626a;
  width: 20px;
  margin-right: 45px;
  justify-self: end;
}

.icon:hover {
  color: black;
  cursor: pointer;
}

#inline-title {
  margin-left: 45px;
  max-width: 60%;
}

.userSettings {
  border: 1px solid rgba(0, 0, 0, 0.405);
  border-radius: 5px;
  position: absolute;
  background-color: white;
}

.userSettingsItems {
  font-size: 16px;
  font-weight: 400;
  padding: 7px;
  color: #44626a;
}

.userSettingsItems:hover {
  background-color: #44626a;
  color: white;
  cursor: pointer;
}

.navIcons {
  text-align: center;
}

@media (max-width: 540px) {
  .firstHeader {
    margin: 35px 0;
  }
  .secondHeaderItems {
    margin: 0;
  }
  .icon {
    margin: 15px;
  }

  #inline-title {
    display: flex;
    align-items: center;
  }
}
