.card,
.editCard {
  color: #44626a;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 25px;
  border-radius: 5px;
  box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 220px;
}

.editCard {
  background-color: #ee7d5f;
  padding: 15px;
}

.editTitle {
  color: white;
  margin-bottom: 10px;
}

.topCard {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  height: 100%;
  border: 1px solid #e7e7e7;
  border-bottom: none;
}

.topCard,
.botCard {
  text-align: center;
}

.botCard {
  background-color: #ee7d5f;
  width: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #ebb3a4;
  border-top: none;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info {
  border-radius: 50%;
  width: 18px;
  position: absolute;
  top: 3px;
  right: 3px;
  background-color: white;
  border: 1px solid rgb(187, 187, 187);
  color: rgb(187, 187, 187);
}

.botCardInput {
  background-color: #ff9b80;
  margin: 5px;
  margin-top: 15px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
  text-align: left;
  font-size: 16px;
}

.botCardSelect {
  color: white;
  border: none;
  margin: 5px;
}

.botCardSelect:hover {
  cursor: pointer;
}

.cardBtnBox {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 8px;
}

.cardBtn {
  background-color: white;
  color: #44626a;
  border: none;
  padding: 9px;
  border-radius: 5px;
  margin: 10px;
  font-size: 12px;
  font-weight: 600;
}

.cardBtn:disabled {
  color: #afafaf;
}

.cardBtn:disabled:hover {
  color: #afafaf;
  cursor: auto;
}

.cardBtn:hover,
.trash:hover {
  color: black;
  cursor: pointer;
}

.trash:hover {
  color: #874534;
}

.trash {
  width: 20px;
  margin: 10px;
  color: white;
}

.logoImage {
  width: 75px;
  margin: 15px 0;
}

.italic {
  font-style: italic;
  font-size: 13px;
}

.cardName {
  font-weight: 600;
}

.alreadyImported {
  background-color: #44626a;
}

.alreadyImported:disabled {
  color: #e5abab;
}

.alreadyImported:disabled:hover {
  color: #e5abab;
}

.infoPopup {
  position: fixed;
  top: 35vh;
  left: 50vw;
  font-size: 15px;
  z-index: 99999;
  background-color: #44626a;
  width: 250px;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 25px;
}

.fadePopup {
  animation: fade 5s steps(90) forwards;
  -webkit-animation: fade 5s steps(90) forwards;
  -moz-animation: fade 5s steps(90) forwards;
}

.refreshArrow {
  width: 15px;
  height: 15px;
  color: #44626a;
}

.refreshArrow:hover {
  color: black;
}

.closeBtn {
  padding: 5px;
  position: absolute;
  top: 0px;
  right: 4px;
}

.closeBtn:hover {
  cursor: pointer;
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in-image {
  animation: fadeIn 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
