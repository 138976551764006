.adviceTitle {
  display: flex;
  align-items: center;
  margin-top: 15px;
  width: fit-content;
  color: #44626a;
  gap: 1px;
}

.adviceTitle:hover {
  cursor: pointer;
  color: black;
}

.adviceCard {
  color: #44626a;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 25px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  width: 350px;
}

.adviceBotCard {
  position: relative;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #ebb3a4;
  border-top: none;
  color: white;
  width: 100%;
  background-color: #ee7d5f;
  padding-bottom: 25px;
}

.adviceTopCard {
  width: 100%;
  height: 100%;
  border: 1px solid #e7e7e7;
  border-bottom: none;
  padding: 15px 0 15px 25px;
}

.adviceItem {
  padding: 10px;
  word-wrap: break-word;
}

.adviceTrash:hover {
  color: black;
  cursor: pointer;
}

.adviceTrash {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 20px;
  margin: 10px;
  color: white;
}

.adviceCardBtn {
  background-color: white;
  color: #44626a;
  border: none;
  padding: 9px;
  border-radius: 5px;
  margin: 10px;
  margin-top: 25px;
}

.adviceCardBtn:hover {
  color: black;
  cursor: pointer;
}

.config {
  font-weight: 700;
}

.linkGeoPackage {
  text-decoration: none;
  background-color: white;
  color: #44626a;
  border: none;
  padding: 9px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}

.linkGeoPackage:hover {
  color: black;
}

@media (max-width: 540px) {
  .tagMargin {
    margin: 25px;
  }

  .adviceCard {
    margin: 0;
    margin-bottom: 35px;
    width: 100%;
  }

  .flexAdviceItems {
    margin-top: 50px;
  }
}
