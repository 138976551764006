.importFields {
  color: white;
  background-color: #44626a;
  margin: 50px;
  padding: 18px;
  font-size: 16px;
  border: none;
  box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.importFields:hover {
  cursor: pointer;
}

.importFields:disabled {
  color: #afafaf;
}

.importFields:disabled:hover {
  cursor: auto;
}

.fieldBox {
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.space {
  margin: 15px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.arrow{
  width: 59px;
  height: 55px;
  background: url("./plug.png");
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}

.hidePlug{
  color: white;
}

@media (max-width: 540px) {
  .fieldBox {
    margin-top: 35px;
  }
}
