.flexForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #44636A;
    padding: 35px;
    border-radius: 5px;
    box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
    color: white;
    width: 300px;
}

.loginBox {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loginTitle {
    font-size: 25px;
    color: #44636A;
}

.flexForm, .loginTitle {
    margin-top: 25px;
}

.loginSubmit {
    color: white;
    background-color: #ED7F61;
    padding: 10px;
    font-size: 16px;
    border: none;
    box-shadow: 5px 7px 5px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-top: 25px;
}

.loginSubmit:hover {
    cursor: pointer;
}
.disabledSubmit {
    background-color: #ed7f616c;
}

.disabledSubmit:hover {
    cursor: default;
}